import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMText } from '../RMText/RMText.js';

import { Icon, Select, SelectContainer, SelectError, SelectWrapper } from './RMSelect.mobile.styles.js';
import { RMSelectProps } from './RMSelect.types.js';

export function RMSelectMobile({
  id,
  label,
  value,
  boldLabel = true,
  error,
  disabled,
  options,
  onChange,
}: RMSelectProps) {
  return (
    <SelectContainer>
      {label && (
        <RMText id={`${id}-label`} type="sans" size="xs" color="on-surface-secondary" bold={boldLabel}>
          {label}
        </RMText>
      )}

      <SelectWrapper>
        <Select
          id={id}
          value={value ?? undefined}
          onChange={(event) => onChange(event.target.value)}
          disabled={disabled}
          aria-labelledby={`${id}-label`}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          ))}
        </Select>

        <Icon>
          <FontAwesomeIcon icon={faChevronDown} color="var(--on-surface-secondary)" size="sm" />
        </Icon>
      </SelectWrapper>

      {error && <SelectError>{error}</SelectError>}
    </SelectContainer>
  );
}
