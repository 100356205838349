import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as RadixSelect from '@radix-ui/react-select';

import { RMText } from '../RMText/RMText.js';

import { SelectContent, SelectItem } from './RMSelect.desktop.styles.js';
import { Icon, Select, SelectContainer, SelectError } from './RMSelect.mobile.styles.js';
import { RMSelectProps } from './RMSelect.types.js';

export function RMSelectDesktop({
  id,
  label,
  boldLabel = true,
  ariaLabel,
  onChange,
  options,
  error,
  value,
  disabled,
}: RMSelectProps) {
  return (
    <RadixSelect.Root
      value={value ?? undefined}
      onValueChange={(newValue) => {
        // https://github.com/radix-ui/primitives/issues/3135
        if (options.findIndex((o) => o.value === newValue) === -1) {
          return;
        }

        onChange(newValue);
      }}
      disabled={disabled}
    >
      <SelectContainer>
        {label && (
          <RMText id={`${id}-label`} type="sans" size="xs" color="on-surface-secondary" bold={boldLabel}>
            {label}
          </RMText>
        )}
        <RadixSelect.Trigger asChild>
          <Select
            id={id}
            value={value ?? undefined}
            onChange={(event) => onChange(event.target.value)}
            disabled={disabled}
            aria-labelledby={label != null ? `${id}-label` : undefined}
            aria-label={ariaLabel}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value} disabled={option.disabled}>
                {option.label}
              </option>
            ))}
          </Select>
        </RadixSelect.Trigger>
        <Icon>
          <FontAwesomeIcon icon={faChevronDown} color="var(--on-surface-secondary)" size="sm" />
        </Icon>
        {error && <SelectError>{error}</SelectError>}
      </SelectContainer>

      <RadixSelect.Content asChild position="popper" side="bottom">
        <SelectContent>
          {options
            // Hide empty options.
            .filter((o) => o.label.length > 0)
            .map((option) => (
              <RadixSelect.Item key={option.value} value={option.value} asChild disabled={option.disabled}>
                <SelectItem data-selected={value === option.value} disabled={option.disabled}>
                  <RMText type="sans" size="s" bold color="on-surface-secondary">
                    {option.label}
                  </RMText>
                </SelectItem>
              </RadixSelect.Item>
            ))}
        </SelectContent>
      </RadixSelect.Content>
    </RadixSelect.Root>
  );
}
